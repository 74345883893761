
import './styles/App.css';
import Navbar from './pages/Navbar';
import Contact from './pages/Contact';
import About from './pages/About';
import Member from './pages/Member';
import Event from './pages/Event';
import GetInvolved from './pages/GetInvolved';
import Footer from './pages/Footer';
import Home from './pages/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NewYear from './components/Confetti/NewYear';
import EventLine from './pages/EventLine';


function App() {

  return (
    <BrowserRouter>
      <div className='bg-white'>
        <Navbar />
        <EventLine/>
        <Routes>
          <Route path="/2025" element={<NewYear/>} />
          <Route exact path="/" element={<Home />} />
          <Route path="/AboutUs" element={<About />} />
          <Route path="/Event" element={<Event />} />
          <Route path="/Member" element={<Member />} />
          <Route path="/GetInvolved" element={<GetInvolved />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </div>
    </BrowserRouter>

  );
}

export default App;