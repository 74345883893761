import React from "react";

export default function EventLine() {
    return (
        <div className="sticky top-16 md:top-20 z-20 w-full bg-black overflow-hidden text-yellow-500 flex justify-center items-center">
            <a
                href="https://www.tickettailor.com/events/nationalsocietyofblackengineers1/1526382"
                target="_blank"
                rel="noreferrer"
            >
                <div className="flex justify-center items-center whitespace-normal sm:whitespace-nowrap animate-pulse">
                    <span className="text-xs sm:text-base md:text-lg lg:text-xl font-medium tracking-wide shadow-md transition-all duration-300 hover:text-coral hover:scale-105 hover:shadow-lg">
                        Come Join Us on February 27th at The{" "}
                        <strong className="uppercase underline">
                            Black Legacy Dinner
                        </strong>
                        ! Get Your Tickets Now
                    </span>
                </div>
            </a>
        </div>
    );
}
