import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import { FaRegCalendarAlt, FaStar } from "react-icons/fa";
import { GiFireworkRocket } from "react-icons/gi";

export default function NewYear() {
    // Get the window dimensions for confetti
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    // Update window dimensions when resizing
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener("resize", handleResize);

        // Cleanup on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="confetti fixed z-1000 w-full h-screen bg-gradient-to-r from-yellow-500 via-yellow-600 to-orange-500 flex items-center justify-center">
            {/* Confetti Component */}
            <Confetti width={windowWidth} height={windowHeight} />

            {/* "Happy New Year" Message */}
            <div className="text-center z-10 flex flex-col items-center space-y-4">
                {/* Adding Icons */}
                <div className="flex space-x-4 mb-6">
                    <GiFireworkRocket className="text-white text-5xl animate-bounce" />
                    <FaStar className="text-white text-5xl animate-spin" />
                    <FaRegCalendarAlt className="text-white text-5xl" />
                </div>

                <h1 className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 via-pink-500 to-purple-600 text-6xl sm:text-7xl md:text-8xl font-extrabold drop-shadow-lg">
                    Happy New Year 2025!
                </h1>

                {/* Additional Styling for Year Text */}
                <p className="text-white text-3xl sm:text-4xl font-semibold mt-2">
                    Wishing you a year filled with joy and success.
                </p>
            </div>
        </div>
    );
}
